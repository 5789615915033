import Head from 'next/head'

import Layout from 'shared/layout'
import LandingPage from 'src/modules/landing'

export default function Home() {
  return (
    <>
      <Head>
        <title>VTR Minting Platform</title>
        <meta name='description' content='Generated by create next app' />
        <link rel='icon' href='/favicon.ico' />
      </Head>

      <Layout hideFooter>
        <LandingPage />
      </Layout>
    </>
  )
}
