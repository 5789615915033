import dynamic from 'next/dynamic'
import { useEffect } from 'react'
import { useRouter } from 'next/router'

import HeroSection from './HeroSection'
import { LandingPageContainer } from './style'
import { FadeIn } from 'shared/animations'

const SocialSection = dynamic(() => import('./SocialSection'), {
  ssr: false,
  loading: () => <></>,
})
const InfoSection = dynamic(() => import('./InfoSection'), {
  loading: () => <></>,
})
const RoadMapSection = dynamic(() => import('./RoadMapSection'), {
  loading: () => <></>,
})
const Footer = dynamic(() => import('shared/components/footer'), {
  loading: () => <></>,
})
const TeamSection = dynamic(() => import('./TeamSection'), {
  loading: () => <></>,
})
const PlatformSection = dynamic(() => import('./PlatformSection'), {
  loading: () => <></>,
})

const LandingPage = () => {
  const router = useRouter()
  useEffect(() => {
    if (typeof window !== 'undefined') {
      router?.push('/#home')
    }
  }, [])
  return (
    <LandingPageContainer>
      <HeroSection />

      <InfoSection />

      <RoadMapSection />

      <FadeIn>
        <TeamSection />
      </FadeIn>
      <FadeIn>
        <PlatformSection />
      </FadeIn>
      <FadeIn>
        <SocialSection />
      </FadeIn>
      <Footer />
    </LandingPageContainer>
  )
}

export default LandingPage
