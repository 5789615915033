import { useRef, useState } from 'react'
import Link from 'next/link'
import dynamic from 'next/dynamic'

import * as Styles from './style'
import { FlexCol } from 'styles/styled'
import { useAppDispatch, useAppSelector } from 'src/logic/redux/hooks'
import {
  setShowConnectModal,
  setShowRegisterModal,
} from 'src/logic/redux/slices/uiSlice'
import PrimaryButton from 'shared/components/mintButton/MintButton'
import { colors } from 'styles/theme'
import MusicOnIcon from 'public/assets/landing/music_note.svg'
import MusicOffIcon from 'public/assets/landing/music_off.svg'
import {
  AUTH_MODAL_STATUS,
  setRegisterProgress,
} from 'src/logic/redux/slices/authSlice'

const BirdsComponent = dynamic(() => import('./components/birds'), {
  ssr: false,
  loading: () => <></>,
})

const HeroSection = () => {
  const dispatch = useAppDispatch()
  const { user } = useAppSelector((state) => state.userReducer)

  const handleMintNowCLick = () => {
    if (user?._id) {
      dispatch(setShowConnectModal(true))
    } else {
      dispatch(setShowRegisterModal(true))
      dispatch(setRegisterProgress(AUTH_MODAL_STATUS.ENTER_EMAIL))
    }
  }
  const [isMusicOn, setIsMusicOn] = useState(false)
  const audioRef = useRef<any>(null)
  const handleMusicClick = () => {
    if (isMusicOn) {
      audioRef?.current?.pause()
      audioRef.current.currentTime = 0
    } else {
      audioRef?.current?.play()
    }

    setIsMusicOn(!isMusicOn)
  }

  return (
    <Styles.HeroSectionContainer id='home'>
      <audio ref={audioRef} src={'/audio/anim_music.mp3'} />
      <Styles.SoundImage
        src={isMusicOn ? MusicOnIcon : MusicOffIcon}
        alt='music'
        onClick={handleMusicClick}
        title={isMusicOn ? 'Pause Music' : 'Play Music'}
      />
      <BirdsComponent />
      <Styles.HeroTextContainer>
        <FlexCol gap='30px' alignItems='stretch'>
          <FlexCol gap='20px' alignItems='center'>
            <h1>Join the VTR Community</h1>
            <p>
              This is the 1st and only functional utility mint of the “Original
              Starling” NFT. The following are the current list of functions of
              owning this NFT. Buying a VTR “Original Starling” costs 1.0 ETH.
              There are no price tiers. All VTR Membership costs the same for
              everyone.
            </p>
          </FlexCol>
          {user?.walletAddress ? (
            <Link href={'/gallery'}>
              <PrimaryButton
                bgColor={colors.modalBackground}
                className='mint-button'
                width='max-content'
                fillOpacity='1'
              >
                MINT NOW
              </PrimaryButton>
            </Link>
          ) : (
            <>
              <PrimaryButton
                bgColor={colors.modalBackground}
                className='mint-button'
                width='max-content'
                onClick={handleMintNowCLick}
                fillOpacity='1'
              >
                MINT NOW
              </PrimaryButton>
            </>
          )}
        </FlexCol>
      </Styles.HeroTextContainer>
    </Styles.HeroSectionContainer>
  )
}

export default HeroSection
