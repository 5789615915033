import Image from 'next/image'
import styled from 'styled-components'

import HeroBg from 'public/assets/landing/hero.avif'
import { FlexCol, FlexRow } from 'styles/styled'
import { colors, screenSizes } from 'styles/theme'
import BenefitsBg from 'public/assets/landing/benefitsBg.svg'
import SocialBg from 'public/assets/landing/socialBg.avif'
import { TextCont } from 'shared/components/cards/style'

export const LandingPageContainer = styled.div`
  height: 100vh;
  overflow-x: hidden;
`

export const HeroSectionContainer = styled.section`
  position: relative;
  display: flex;
  justify-content: center;
  height: 100%;

  background: url(${HeroBg?.src}) no-repeat center;
  background-size: cover;
`

export const HeroTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  height: fit-content;

  position: absolute;
  z-index: 2;
  margin: 0 auto;

  position: absolute;
  top: 20%;

  text-align: center;

  max-width: 100%;
  padding: 0 20px;
  h1 {
    font-weight: 700;
    font-family: var(--font-tomorrow);
    font-size: 36px;
    line-height: 40px;
    text-transform: uppercase;
  }

  p {
    font-family: var(--font-dm-mono);
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    color: ${colors.borderColor};
    text-align: justify;
  }

  .mint-button,
  a {
    align-self: center;
  }

  @media (min-width: ${screenSizes.XS}px) {
    top: 10%;
  }

  @media (min-width: ${screenSizes.M}px) {
    max-width: 720px;
    top: 20%;

    h1 {
      font-size: 40px;
      font-size: 36px;
      line-height: 72px;
    }

    p {
      text-align: center;
      font-size: 16px;
    }
  }
`

// ============== Info-Section ==============

export const InfoSectionContainer = styled.section`
  padding: 90px 30px;
  position: relative;
  z-index: 2;
  background: transparent !important;

  @media (min-width: ${screenSizes.M}px) {
    padding: 80px 30px;
  }

  @media (min-width: ${screenSizes.XLL}px) {
    padding: 80px 118px;
  }

  &::after {
    content: '';
    height: 2729px;
    width: 2500px;
    left: -1900px;
    top: 0px;
    position: absolute;
    z-index: 1;
    background: radial-gradient(
      50% 50% at 50% 50%,
      #2393b8 0%,
      rgba(0, 0, 0, 0) 100%
    );
    opacity: 0.5;

    display: none;

    @media (min-width: 1150px) {
      display: inline-block;
    }

    @media (min-width: 1820px) {
      width: 100%;
      left: 0%;

      background: radial-gradient(
        50% 50% at 50% 50%,
        rgba(35, 147, 184, 0.6) 0%,
        rgba(0, 0, 0, 0) 100%
      );
    }
  }
`

// ============== RoadMap-Section ==============

export const RoadMapSectionContainer = styled.section`
  min-height: 100vh;
  padding: 20px 0;

  & > div {
    gap: 60px;
  }

  @media (min-width: ${screenSizes.L}px) {
    & > div {
      gap: 150px;
    }
  }

  @media (min-width: ${screenSizes.XL}px) {
    padding: 0 96px;
  }
`

export const HeadingContainer = styled(FlexCol)`
  gap: 8px;
  text-align: center;
  align-items: center;
  padding-top: 40px;

  @media (min-width: ${screenSizes.M}px) {
    padding-top: 10px;
  }

  h2 {
    font-family: var(--font-tomorrow);
    font-weight: 700;
    font-size: 40px;
    line-height: 72px;
    text-transform: uppercase;
  }

  p {
    font-family: var(--font-dm-mono);
    text-transform: uppercase;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    text-align: left;
  }

  @media (min-width: ${screenSizes.XS}px) {
    p {
      text-align: center;
    }
  }
`

export const RoadMapCardsContainer = styled(FlexRow)`
  flex-direction: column;
  align-items: flex-start;
  gap: 32px;

  ${TextCont} {
    padding: 24px 20px;
  }

  @media (min-width: ${screenSizes.M}px) {
    flex-direction: row;
    gap: 80px;

    &.reverse {
      flex-direction: row-reverse;
    }

    ${TextCont} {
      padding: 30px;
    }
  }

  @media (min-width: ${screenSizes.XL}px) {
    gap: 120px;
  }
`

export const RoadMapInfo = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;

  padding: 22px 2px;

  h3 {
    font-family: var(--font-tomorrow);
    font-size: 16px;
    line-height: 24px;
  }

  p {
    font-family: var(--font-dm-mono);
    font-weight: 500;
    font-size: 12px;
    line-height: 24px;
  }

  @media (min-width: ${screenSizes.XS}px) {
    h3 {
      font-size: 20px;
    }

    p {
      font-size: 14px;
    }
  }
`

export const RoadMapCardsWrapper = styled(FlexCol)`
  gap: 60px;

  @media (min-width: ${screenSizes.M}px) {
    gap: 150px;
  }

  & > div:nth-child(odd) {
    align-self: flex-end;
  }
  & > div:nth-child(even) {
    flex-direction: row-reverse;
  }
`

export const RoadMapImage = styled(Image)`
  width: 150px;
  height: 150px;

  @media (min-width: ${screenSizes.M}px) {
    width: 206px;
    height: 206px;
  }
`

// ============= Benefits-section =============

export const BenefitsSectionCont = styled.div`
  background: url(${BenefitsBg?.src}) no-repeat center;
  min-height: 100vh;

  position: relative;
  z-index: 2;

  & > div {
    justify-content: center;
  }

  display: none;

  @media (min-width: 1080px) {
    display: block;
  }

  & > p {
    max-width: 1320px;
    margin: 0 auto;
    margin-top: 40px;
    padding: 0 40px;
    padding-bottom: 60px;
    font-family: var(--font-dm-mono);
    font-size: 16px;
    color: ${colors.borderHighlight};

    span {
      border-bottom: 1px solid ${colors.primary};
    }
  }
`

export const MobileBenefitsSectionCont = styled.div`
  display: block;
  padding: 0 24px;

  .slick-prev,
  .slick-next {
    top: 30%;
  }

  .slick-prev {
    transform: rotate(180deg);
  }

  @media (min-width: 1080px) {
    display: none;
  }

  & > p {
    max-width: 1320px;
    margin: 0 auto;
    margin-top: 0px;
    font-family: var(--font-dm-mono);
    font-size: 16px;
    color: ${colors.borderHighlight};
  }
`

// ============= Teams-section =============

export const TeamSectionCont = styled.div`
  padding: 30px 24px;
  background: ${colors.darkBg};

  h1 {
    font-family: var(--font-tomorrow);
    font-size: 26px;
    line-height: 60px;
    text-transform: uppercase;
  }

  p {
    font-family: var(--font-dm-mono);
    font-size: 14px;
    font-weight: 500;
    color: rgba(255, 255, 255, 0.5);
  }

  & > div {
    flex-direction: column;

    padding-top: 60px;

    @media (min-width: ${screenSizes.M}px) {
      padding-top: 0px;
    }
  }

  @media (min-width: 370px) {
    h1 {
      font-size: 36px;
    }
  }

  @media (min-width: ${screenSizes.M}px) {
    padding: 160px 20px;

    h1 {
      font-size: 40px;
    }
    p {
      font-size: 16px;
    }
  }

  @media (min-width: 1060px) {
    & > div {
      flex-direction: row;
    }
  }

  @media (min-width: ${screenSizes.XL}px) {
    padding: 160px 60px;
  }

  @media (min-width: ${screenSizes.XLL}px) {
    & > div {
      gap: 60px;
    }
  }
`

export const ImageCont = styled.div`
  position: relative;

  img {
    position: absolute;
    top: 25px;
    left: -56px;
    display: none;
  }

  @media (min-width: 1060px) {
    img {
      display: inline-block;
    }
  }
`

// ================ Social-Section ================

export const SocialSectionCont = styled.div`
  background: url(${SocialBg?.src}) no-repeat center;
  padding-top: 138px;
  width: 100%;
  padding: 50px 16px;
  overflow: hidden;
  background-size: cover;
  height: 100%;
  & > div {
    max-width: 1024px;
    margin: 0 auto;
    gap: 40px;
  }

  h1 {
    font-family: var(--font-tomorrow);
    font-size: 40px;
    font-weight: 700;
    text-transform: uppercase;
    text-align: center;
  }

  ${TextCont} {
    padding: 20px;
  }

  @media (min-width: 400px) {
    padding: 50px 24px;

    ${TextCont} {
      padding: 30px;
    }
  }

  @media (min-width: ${screenSizes.M}px) {
    padding: 138px 16px 0 16px;
    min-height: 668px;
    & > div {
      gap: 60px;
    }
    padding: none;

    h1 {
      font-size: 48px;
    }
  }
`

export const ContractDetails = styled.p`
  width: max-content;
  margin: 0 auto;
  margin-top: 32px;
  color: ${colors.borderHighlight};

  font-size: 10px;
  font-family: var(--font-tomorrow);
  font-weight: 600;
  line-height: 28px;

  a {
    color: ${colors.white};
    font-weight: 400;
    text-decoration: underline;

    &:hover {
      filter: brightness(75%);
    }
  }

  @media (min-width: 420px) {
    font-size: 14px;
  }

  @media (min-width: ${screenSizes.M}px) {
    font-size: 20px;
  }
`

export const AcknowledgeText = styled.p`
  text-transform: none;
  font-size: 14px;
  font-family: var(--font-dm-mono);
  font-weight: 500;
  color: ${colors.borderHighlight};
  max-width: 1320px;
  margin: 0 auto;
  margin-top: 60px;
  padding-bottom: 117px;
  line-height: 28px;

  @media (min-width: ${screenSizes.XS}px) {
    font-size: 16px;
  }
`

export const IconCont = styled.div`
  display: flex;
  min-width: 40px;
  justify-content: center;
  align-items: center;
  padding: 8px;

  border: 1px solid ${colors.borderColor};
  transition: filter 0.5s;
`

export const CardCont = styled.div`
  h5 {
    font-family: var(--font-tomorrow);
    font-size: 20px;
    font-weight: 600;
  }

  p {
    font-family: var(--font-dm-mono);
    font-size: 16px;
    color: rgba(255, 255, 255, 0.5);
  }

  &:hover {
    ${IconCont} {
      transition: filter 0.5s, background-color 0.5s;
      background-color: ${colors.primary};

      img {
        filter: brightness(0) invert(1);
      }
    }
  }
`

// ================ Platform-Section ================

export const PlatformSectionContainer = styled.section`
  p {
    text-align: center;
    font-size: 14px;
    font-family: var(--font-dm-mono);
    font-weight: 500;
    line-height: 24px;
    color: ${colors.borderHighlight};
    margin-bottom: 40px;
  }

  @media (min-width: 1080px) {
    p {
      margin-bottom: 100px;
    }
  }
`

export const SoundImage = styled(Image)`
  position: absolute;
  top: 100px;
  right: 5%;
  cursor: pointer;
  width: 30px;
  height: 30px;
  z-index: 5;
`
