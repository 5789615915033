import { CSSProperties } from 'styled-components'
import { useRef } from 'react'

import { useElementOnScreen } from 'shared/hooks/useElementOnScreen'

interface IAnimateInProps {
  from: CSSProperties
  to: CSSProperties
  children: React.ReactNode
  threshold: number
}
const AnimateIn = ({
  from,
  to,
  children,
  threshold = 0.2,
}: IAnimateInProps) => {
  const ref = useRef<HTMLDivElement>(null)
  const onScreen = useElementOnScreen(ref, threshold)
  const defaultStyles: CSSProperties = {
    transition: '600ms ease-in-out',
  }
  return (
    <div
      ref={ref}
      style={
        onScreen
          ? {
              ...defaultStyles,
              ...to,
            }
          : {
              ...defaultStyles,
              ...from,
            }
      }
    >
      {children}
    </div>
  )
}

interface IFadeProps {
  children: React.ReactNode
  threshold?: number
}

export const FadeIn = ({ children, threshold = 0 }: IFadeProps) => (
  <AnimateIn from={{ opacity: 0 }} to={{ opacity: 1 }} threshold={threshold}>
    {children}
  </AnimateIn>
)
export const FadeLeft = ({ children, threshold = 0 }: IFadeProps) => (
  <AnimateIn
    from={{ opacity: 0, transform: 'translateX(-100px)' }}
    to={{ opacity: 1, transform: 'translateX(0px)' }}
    threshold={threshold}
  >
    {children}
  </AnimateIn>
)
export const FadeRight = ({ children, threshold = 0 }: IFadeProps) => (
  <AnimateIn
    from={{ opacity: 0, transform: 'translateX(50px)' }}
    to={{ opacity: 1, transform: 'translateX(0px)' }}
    threshold={threshold}
  >
    {children}
  </AnimateIn>
)

export const shimmer = (w: number, h: number) => `
<svg width="${w}" height="${h}" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
  <defs>
    <linearGradient id="g">
      <stop stop-color="#333" offset="20%" />
      <stop stop-color="#222" offset="50%" />
      <stop stop-color="#333" offset="70%" />
    </linearGradient>
  </defs>
  <rect width="${w}" height="${h}" fill="#333" />
  <rect id="r" width="${w}" height="${h}" fill="url(#g)" />
  <animate xlink:href="#r" attributeName="x" from="-${w}" to="${w}" dur="1s" repeatCount="indefinite"  />
</svg>`
