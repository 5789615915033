import { RefObject, useEffect, useState } from 'react'

export function useElementOnScreen(
  ref: RefObject<Element>,
  threshold: number,
  rootMargin = '0px'
) {
  const [isIntersecting, setIsIntersecting] = useState(true)
  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        setIsIntersecting(entry.isIntersecting)
      },
      { rootMargin, threshold }
    )
    if (ref.current) {
      observer.observe(ref.current)
    }
    return () => {
      if (ref.current) {
        // eslint-disable-next-line react-hooks/exhaustive-deps
        observer.unobserve(ref?.current)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  return isIntersecting
}
